// strona dashboardu
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import qs from 'query-string';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LineHighlightPlot, LinePlot } from '@mui/x-charts/LineChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';

import { reduxActions, useDispatch } from 'store';
import { SearchInput } from 'components/controls';

import { useLoadData } from 'plugins/api/endpoints';
import { loadDataUrlParser } from 'plugins/util/parser';
import {
  IDynamicFilter,
  IDynamicSeries,
  ILoadDataParam,
  IComponentParams
} from 'plugins/api/types';

import { FilterSelect, FilterDate, FilterList } from 'plugins/components/controls';

import 'plugins/theme/components/TablePanel/TablePanel.scss';
import styles from 'theme/pages/Clients/Clients.module.scss';

interface IQueryParams {
  page: number;
  limit: number;
  search_keyword?: string;
  [key: string]: any;
}

// typ danych wejściowych
interface IProps {
  filters?: IDynamicFilter[];
  series: IDynamicSeries[];
  xFieldSymbol: string;
  loadDataParams?: ILoadDataParam;
  componentParams?: IComponentParams;
  title?: string;
  pageSymbol: string;
  componentSymbol: string;
  requestParams?: object;
}

const ChartPanel: FC<IProps> = ({
  filters,
  series,
  xFieldSymbol,
  loadDataParams,
  title,
  pageSymbol,
  componentSymbol,
  requestParams
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  // parametry zapytania o listę klientów
  const [queryParams, setQueryParams] = useState<IQueryParams>({
    page: 1,
    limit: 20,
    search_keyword: ''
  });

  const loadDataUrl = loadDataUrlParser(loadDataParams?.get_url || '', {
    ...requestParams,
    ...qs.parse(search)
  });

  // pobranie danych tabeli
  const { data: chartData, refetch: refetchChartData } = useLoadData(loadDataUrl, queryParams, {
    onSuccess: (data) => {
      dispatch(reduxActions.setContextData({ [pageSymbol]: { [componentSymbol]: data } }));
    },
    enabled: false
  });

  // aktualizacja danych
  useEffect(() => {
    refetchChartData();
  }, [loadDataUrl, queryParams]);

  const seriesDataToDisplay = useMemo(
    () =>
      series.map((o) => ({
        label: o.label,
        type: o.type,
        color: o.color,
        yAxisId: o.field_symbol,
        data: chartData?.items.map((data) => data[o.field_symbol]) || []
      })),
    [chartData, series]
  );

  const renderFilters = () =>
    filters?.map((filter) => {
      if (filter.type === 'search') {
        return (
          <div className="filter">
            <SearchInput
              placeholder={filter.label}
              onChange={(value) =>
                setQueryParams((prevState) => {
                  const newQueryParams = {
                    ...prevState,
                    [filter.field_symbol || 'search_keyword']: value,
                    page: 1
                  };

                  dispatch(
                    reduxActions.setContextFilters({
                      [pageSymbol]: { [componentSymbol]: newQueryParams }
                    })
                  );

                  return newQueryParams;
                })
              }
              value={queryParams[filter.field_symbol || 'search_keyword']}
            />
          </div>
        );
      }

      if (filter.type === 'select') {
        return (
          <FilterSelect
            pageSymbol={pageSymbol}
            componentSymbol={componentSymbol}
            filter={filter}
            setQueryParams={setQueryParams}
            queryParams={queryParams}
          />
        );
      }

      if (filter.type === 'datetime' || filter.type === 'date') {
        return (
          <FilterDate
            pageSymbol={pageSymbol}
            componentSymbol={componentSymbol}
            filter={filter}
            setQueryParams={setQueryParams}
            queryParams={queryParams}
            isTimePicker={filter.type === 'datetime'}
          />
        );
      }

      if (filter.type === 'list') {
        return (
          <FilterList
            pageSymbol={pageSymbol}
            componentSymbol={componentSymbol}
            filter={filter}
            setQueryParams={setQueryParams}
            queryParams={queryParams}
          />
        );
      }

      return null;
    });

  if (chartData && series) {
    return (
      <div className={classnames(styles.componentWrapper, 'ChartPanel', 'StylePath-ChartPanel')}>
        <h2>{title}</h2>
        <div className={styles.filtersWrapper}>
          <div className="filterWrapper">{renderFilters()}</div>
        </div>
        <div className="chartWrapper">
          <ResponsiveChartContainer
            series={seriesDataToDisplay}
            height={400}
            margin={{ top: 10 }}
            xAxis={[
              {
                id: xFieldSymbol,
                data: chartData?.items.map((day) => new Date(day.date)),
                scaleType: 'band',
                valueFormatter: (value) => value.toLocaleDateString()
              }
            ]}
            yAxis={series.map((o) => ({ id: o.field_symbol }))}>
            <BarPlot />
            <LinePlot />
            <LineHighlightPlot />
            <ChartsXAxis
              position="bottom"
              axisId={xFieldSymbol}
              tickLabelStyle={{
                fontSize: 10
              }}
            />
            {series.map((o, i) => (
              <ChartsYAxis
                key={i}
                label={o.label}
                position={i === 0 ? 'left' : 'right'}
                axisId={o.field_symbol}
                tickLabelStyle={{ fontSize: 10 }}
              />
            ))}
            <ChartsTooltip />
          </ResponsiveChartContainer>
        </div>
      </div>
    );
  }

  return null;
};

export default ChartPanel;
